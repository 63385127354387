export const skills = [
  {
    title: "Mobile Development",
    skills: [
      {
        name: "Flutter",
        image: "https://dadixcod.com/assets/flutter_colored.png",
      },
      {
        name: "Dart",
        image: "https://dadixcod.com/assets/dart.png",
      },
      {
        name: "Bloc",
        image: "https://dadixcod.com/assets/bloc.png",
      },
      {
        name: "Kotlin",
        image: "https://dadixcod.com/assets/kotlin.png",
      },
      {
        name: "Java",
        image: "https://dadixcod.com/assets/java.png",
      },
      {
        name: "Compose",
        image: "https://dadixcod.com/assets/compose.png",
      },
      {
        name: "XML",
        image: "https://dadixcod.com/assets/xml.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "NodeJS",
        image: "https://dadixcod.com/assets/node-js.png",
      },
      {
        name: "ExpressJS",
        image: "https://dadixcod.com/assets/express-js.png",
      },
      {
        name: "MongoDB",
        image: "https://dadixcod.com/assets/mongo-db.png",
      },
      {
        name: "PostgreSQL",
        image: "https://dadixcod.com/assets/postgresql.png",
      },
      {
        name: "Firebase",
        image: "https://dadixcod.com/assets/firebase.png",
      },
    ],
  },
  {
    title: "Design",
    skills: [
      {
        name: "Photoshop",
        image: "https://dadixcod.com/assets/photoshop.png",
      },
      {
        name: "Illustrator",
        image: "https://dadixcod.com/assets/illustrator.png",
      },
      {
        name: "Figma",
        image: "https://dadixcod.com/assets/figma.png",
      },
      {
        name: "Adobe XD",
        image: "https://dadixcod.com/assets/adobexd.png",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image: "https://dadixcod.com/assets/git.png",
      },
      {
        name: "GitHub",
        image: "https://dadixcod.com/assets/github.png",
      },
      {
        name: "VS Code",
        image: "https://dadixcod.com/assets/vs-code.png",
      },
      {
        name: "Postman",
        image: "https://dadixcod.com/assets/postman.png",
      },
      {
        name: "Android Studio",
        image: "https://dadixcod.com/assets/android-studio.png",
      },
    ],
  },
];

export const projects = [
  {
    id: 0,
    title: "My Mib",
    description:
      "Mobile app that can help you track all your expenses and incomes monthly,weekly,daily & more .",
    category: "mobile",
    image: "https://dadixcod.com/assets/mymib.png",
    repository: "https://github.com/dadixCod/mymib",
    tags: [
      {
        tag: "Flutter",
      },
      {
        tag: "Dart",
      },
      {
        tag: "Bloc",
      },
      {
        tag: "Firebase",
      },
      {
        tag: "SQLite",
      },
      {
        tag: "FLCharts",
      },
    ],
    videoUrl: "https://dadixcod.com/assets/mymibvideo.mp4",
  },
  {
    id: 1,
    title: "Local Pharm",
    description:
      "Mobile app that can help you track your home's medicaments and notify you abour their expiry date.",
    category: "mobile",
    image: "https://dadixcod.com/assets/localpharm.png",
    repository: "https://github.com/dadixCod/localPharm",
    tags: [
      {
        tag: "Flutter",
      },
      {
        tag: "Dart",
      },
      {
        tag: "Bloc",
      },
      {
        tag: "SQLite",
      },
    ],
    videoUrl: "https://dadixcod.com/assets/localpharmvideo.mp4",
  },
  {
    id: 3,
    title: "Equipement Manager",
    description:
      "This Full Stack Web app was developed for the CNR company to let the admin track all the IT Equipements inside the company.",
    category: "web",
    image: "https://dadixcod.com/assets/Itequipement.png",
    repository: "https://github.com/dadixCod/fullstack-stage",
    tags: [
      {
        tag: "ReactJS",
      },
      {
        tag: "NodeJS",
      },
      {
        tag: "ExpressJS",
      },
      {
        tag: "PostgreSQL",
      },
      {
        tag: "JWT",
      },
    ],
    videoUrl: "https://dadixcod.com/assets/itequipementvideo.mp4",
  },
];
